<template>
  <div class="role-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="authentication/role/page">
      <el-button
        v-if="$auth('角色管理新增')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.editPage.open(0)">
        {{$l('common.add','新增')}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('role.name','角色名称')">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="name" :label="$l('role.name','角色名称')"></el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center">
        <template slot-scope="scope">
          <el-button v-if="$auth('角色管理编辑')" type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l('common.edit','编辑')}}</el-button>
          <el-button v-if="$auth('角色管理删除')" type="danger" @click="handleDelete(scope.row)">{{$l('common.delete','删除')}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList"></edit-page>
    <role-real-edit ref="roleRealEdit" @save-success="getList"></role-real-edit>
  </div>
</template>
<script>
  import EditPage from "./RoleEdit.vue";
  import RoleRealEdit from "@/views/role/RoleRealEdit";

  export default {
    components: {EditPage,RoleRealEdit},
    data() {
      return {
        filter: {
          name: "",
        },
      };
    },
    mounted() {
      this.getList();
    },
    methods: {
      getList() {
        this.$refs.vmTable.getList();
      },
      handleDelete(row) {
        this.$confirm(`确定删除${row.name}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("authentication/role", row.id).then(() => {
            this.getList();
            this.$message.success(this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .role-list {
    .permissionLabel {
      display: inline-block;
      border-radius: 2px;
      background-color: #fafafa;
      border: 1px solid #E4E7ED;
      color: #666;
      padding: 0 7px;
      margin: 2px;
      font-size: 11px;
    }
  }
</style>
