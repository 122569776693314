<template>
  <el-dialog
    v-el-drag-dialog
    :title="role.id?$l('role.edit','绑定楼盘'):$l('role.add','绑定楼盘')"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    class="role-edit-model"
    width="1200px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <vm-search label="选择城市：">
      <vm-district-select
        :province-code.sync="filter.provinceCode"
        :city-code.sync="filter.cityCode"
        :area-code.sync="filter.districtCode"
        :direct-name.sync="filter.directName">
      </vm-district-select>
    </vm-search>
    <el-button type="primary" @click="getMenu">搜索</el-button>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        label="楼盘">
        <template slot-scope="scope">{{scope.row.name}}</template>
      </el-table-column>
      <el-table-column
        label="地区">
        <template slot-scope="scope">{{scope.row.districtName}}</template>
      </el-table-column>
    </el-table>

    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$l("common.cancel","取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save","保存")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        contentLoading: true,
        submitLoading: false,
        tabsValue:"first",
        permissions: [],
        filter:{},
        checkedPermissionIds: [],
        menus:[],
        role: {
          id: 0,
          name: "",
          permissions: [],
        },
        defaultProps: {
          children: "children",
          label: "name",
        },
        tableData:[],
        multipleSelection:[],
      };
    },
    watch:{
      // menus:{
      //   handler(prams) {
      //     console.log(prams,101);
      //   },
      //   deep:true,
      // },
    },
    mounted() {
      // this.getMenu();
    },
    methods: {
      toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },

      handleClick(tab) {
        if (tab.name==="first") {
          this.getMenu("maintenance_company_backend");
        }else if (tab.name==="second") {
          this.getMenu("maintenance_company_app");
        }else if (tab.name==="third") {
          this.getMenu("customer_company_app");
        }
      },
      open(id) {
        this.role.id = id;
        this.dialogVisible = true;
        // this.getData();
        this.tabsValue="first";
        console.log(1755555);
        this.getMenu();
      },
      getMenu() {
        console.log(this.filter,116);
        this.$http.get("/real-estate/row-statistics/list",this.filter).then(res => {
          this.tableData = res;
          console.log(88,res);
          // this.menus = res;
          this.getData();
        });
      },
      getChecked(v,data) {
        if (v.id===data.id) {
          return true;
        }else if (data.children) {
          let locked =false;
          for (let i = 0; i < data.children.length; i++) {
            if (this.getChecked(v,data.children[i])) {
              locked=true;
            }
          }
          return  locked;
        }else {
          return false;
        }
      },
      formatMenus(item,data) {
        return item.map(v=>{
          for (let i = 0; i < data.length; i++) {
            if (this.getChecked(v,data[i])) {
              v.checked=true;
            }
            if (v.children) {
              v.children= this.formatMenus(v.children,data);
            }
          }
          return v;
        });
      },
      getData() {
        try {
          // this.permissions = await this.$http.get("basic/authority/tree");
          if (this.role.id) {
            this.contentLoading = true;

            this.$http.get(`authentication/role/approve/${this.role.id}`).then(res=>{


              console.log(res.realEstateIds,210);
              if (res.realEstateIds instanceof Array) {
                this.toggleSelection(this.tableData.filter(item=>res.realEstateIds.includes(item.id)));

              }
              this.contentLoading = false;
            });


          }
          this.contentLoading = false;
        } catch (e) {
          this.contentLoading = false;
        }
      },
      handleSubmit() {

        this.submitLoading = true;
        console.log(this.role,223);

        this.$http
          .put("authentication/role/approve",{id:this.role.id,realEstateIds:this.multipleSelection.map(item=>item.id)})
          .then(() => {
            this.submitLoading = false;
            this.dialogVisible = false;
            this.$emit("save-success");
            this.$message.success("保存成功");
          })
          .catch(() => {
            this.submitLoading = false;
          });
      },

    },
  };
</script>

<style lang="scss">
  @import "../../style/variables";

  .role-edit-model {
    width: 1920px;
    .permissionContent {
      border: 1px solid $border-color;
      border-radius: 2px;
    }

    .permissionTitle {
      font-size: 13px;
      padding: 9px 12px;
      background-color: #f5f5f5;
      margin-bottom: 10px;
    }

    .el-checkbox__label {
      font-size: 13px;
    }

  }
</style>
